.container * {
  margin: 0;
  padding: 0;
  color: #000;
}

.modalContainer {
  padding: 0px !important;
  border-radius: 20px !important;
}

.container img {
  width: 100%;
  margin-bottom: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.container {
  background-color: #fff;
  box-sizing: border-box;
  padding-bottom: 20px;
  border-radius: 20px;
  margin: auto;
}

.container a:not(.link),
.submitButton {
  background: linear-gradient(180deg, #de3435, #6b0000);
  display: flex;
  flex-direction: column;
  color: #fff;
  max-width: fit-content;
  margin: 40px auto 0px auto;
  border: none;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.5rem;
}

.submitButton:disabled {
  background: rgb(188, 188, 188);
}

.container p {
  padding: 0px 20px;
}

.container a:not(.link),
.container a:not(.link):hover,
.container a:not(.link):focus {
  color: #fff;
  text-decoration: none;
}

.closeButton {
  position: absolute;
  right: -22px;
  top: -22px;
  background: none;
  border: none;
  background-color: rgb(167, 167, 167);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}

.closeButton svg {
  font-size: 2.25rem;
}

.inputContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  flex-direction: column;
}

.modalContainer input {
  min-height: 40px;
  margin: auto auto auto 10%;
  width: 80%;
  border: 2px solid rgb(181, 181, 181);
  border-radius: 5px;
  padding: 0px 0.5rem;
}

p.error {
  color: red;
  margin: 10px auto 0px;
  padding: 0px;
  width: 80%;
}

.submitButton {
  margin: 10px auto 20px;
}

.link {
  color: #b1011d;
}

.container h3 {
  width: 80%;
  margin: auto auto 10px !important;
}

p.disclaimer {
  margin: 10px auto 10px;
  margin-top: 10px;
  width: 80%;
  padding: 0px;
}

@media screen and (max-width: 400px) {
  .modalContainer {
    max-width: 90%;
    margin: auto;
  }
}
