.navLink {
    color: #f0f0f0;
    outline: 0;
    text-decoration: none;
}

.navLink .anchorLink, 
.anchorLink,
.active {
    font-size: 1em;
}

.navLink a:focus {
    outline: 0;
}

.active {
    color: #fff;

}

.navLink:hover {
    color: #d8d8d8;
}

@media screen and (max-width: 1690px) {
    .navLink .anchorLink {
        font-size: 0.9em;
    }
}

@media screen and (max-width: 1400px) {
    .navLink .anchorLink {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 1200px) {
    .navLink .anchorLink {
        font-size: 1em !important;
    }
}
