.siteNotificationCookieUsage {
  position: fixed;
  width: auto;
  height: auto;
  max-width: 450px;
  background: linear-gradient(#494771, #110c27);
  z-index: 10000;
  bottom: 0px;
  border-radius: 10px;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  color: #fff;
}

.siteNotificationCookieUsage h3 {
  margin: 0px auto 10px auto;
}

.link {
  color: #d87182;
  text-decoration: none;
}

.link:hover {
  color: #f07f91;
  text-decoration: none;
}

.btnSuccess {
  border-radius: 5px;
  color: #fff;
  background-color: #98013b;
  border: none;
  float: right;
  padding: 5px 10px;
  text-decoration: none;
}

.btnSuccess:hover {
  text-decoration: none;
  color: #fff;
}

.siteNotificationCookieUsage * {
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}
