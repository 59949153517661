#global-notification-message {
  font-size: 16px;
  color: white;
}

#global-notification-message:hover {
  text-decoration: none;
}

#global-notification-with-image-container {
  display: flex;
  align-items: center;
}

.send-tip-button {
  border-radius: 20px;
  border: none;
  background: white;
  color: rgb(0, 100, 229);
  padding: 3px 5px;
  margin: 0 5px;
}