.affiliate.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    width: 100%;
    padding: 0 !important
}

.affiliate-lander-container {
    width: 100vw;
}

.affiliate-lander-container img {
    width: 100%;
}

.affiliate.container h1.title-large {
    font-size: 6.5rem;
}

.affiliate.container .title-large {
    font-weight: bolder;
}

.affiliate.container h2.title-large {
    font-size: 4.5rem;
}

.audition-form-success-message,
.affiliate.container h2.title-large,
.affiliate.container .color-dark {
    color: #000;
}

.affiliate.container .content.pull-right {
    margin-left: 25%;
}

.affiliate.container .content {
    background: none;
    color: #fff !important;
    min-height: inherit;
}

.affiliate.container.white-bg {
    background-color: #fff;
    margin: 0px;
    width: 100%;
}

.affiliate.container .bolder {
    font-weight: 900;
}

.affiliate.container.white-bg .content {
    color: rgb(49, 49, 49) !important;
}

.affiliate.container .btn.full-width {
    min-width: 33.33%;
    min-height: 80px;
    color: #fff !important;
    margin: 20px auto;
}

.affiliate.container .btn.full-width:hover {
    color: #fff !important;
}

.affiliate.container .btn {
    color: #fff;
    background-color: #be0000;
    padding: 15px;
    min-width: 195px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    font-size: 2rem;
    margin-top: 25px;
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: 2px;
}

.affiliate-detail {
    flex: 1;
    padding: 5%;
}

.affiliate.container .gray {
    color: rgb(128, 128, 128) !important;
}

.affiliate-detail h4 {
    line-height: 1.5;
    color: #000;
}

.affiliate-detail img {
    width: 200px;
}

.bottom-section.affiliate.container .affiliate-detail h4.gray {
    color: #fff !important;
}

.content-bg {
    min-height: inherit;
    flex: 1;
}

.top-section.affiliate.container {
    background-image: url('../../assets/Affiliate/first-section-desktop.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.bottom-section.affiliate.container .details .content-bg {
    background-position-x: 75%;
    background-repeat: no-repeat;
    background-size: cover;
}

.navbar-topnav.affiliate-topnav {
    position: absolute;
    background-color: rgba(125, 125, 125, 0.5);
    border: none;
    z-index: 9999;
    width: 100%;
}

.navbar-topnav.affiliate-topnav .navbar-topnav.subnav,
.affiliate-topnav #red-background {
    display: none;
}

.navbar-topnav.affiliate-topnav #mobile-header-nav {
    background-color: rgba(0, 0, 0, 0);
}

.navbar-topnav.affiliate-topnav .navbar-toggle.navbar-toggle-custom {
    background-color: transparent;
    border: none;
    display: block;
    position: absolute;
    z-index: 999;
    left: 0;
}

.navbar-topnav.affiliate-topnav .navbar-brand {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.navbar-topnav.affiliate-topnav #mobile-header-nav.show .navbar-left {
    display: flex !important;
    position: absolute;
    z-index: 999;
    flex-direction: column;
    background-color: rgba(125, 125, 125, 0.5);
    top: 67px;
    width: auto;
    max-width: 300px;
}

.navbar-topnav.affiliate-topnav #mobile-header-nav .navbar-left {
    display: none;
}

.navbar-topnav.affiliate-topnav #mobile-header-nav.show .navbar-left {
    animation: toggleMenuOpen 0.5s;
    animation-iteration-count: 1;
    left: 0px;
}

.navbar-topnav.affiliate-topnav .navbar-collapse .navbar-left {
    display: none;
}

.navbar-topnav.affiliate-topnav #mobile-header-nav {
    display: block !important;
}

.navbar-topnav.affiliate-topnav .navbar-collapse .navbar-right {
    display: block !important;
}

.navbar-topnav.affiliate-topnav .navbar-login-link {
    position: absolute;
    z-index: 999;
    right: 10px;
}

.navbar-topnav.affiliate-topnav .navbar-left li a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.affiliate .form .contact-form input,
.affiliate .form .contact-form textarea {
    border: 1px solid rgb(236, 236, 236);
    background-color: rgb(236, 236, 236);
    min-height: 45px;
    color: black !important;
    font-size: 18px;
    font-weight: bold;
}

.contact-form.inputs {
    margin: 5% auto;
}

.affiliate.container.white-bg.form {
    align-items: flex-start !important;
    padding-left: 15% !important;
}

.affiliate.container form.gray {
    max-width: 550px;
    width: 100%;
}

.contact-form-submit {
    border: none;
    border-radius: 5px;
    background-color: rgb(71, 9, 170);
    min-height: 35px;
    min-width: 160px;
    font-weight: bold;
}

@keyframes toggleMenuOpen {
    from {
        left: -150px;
    }

    to {
        left: 0px;
    }
}

@media screen and (max-width: 992px) {
    .details {
        flex-direction: column !important;
    }

    .navbar-topnav.affiliate-topnav #mobile-header-nav.show .navbar-left {
        top: 50px;
    }

    .navbar-topnav.affiliate-topnav .navbar-login-link {
        margin: 5px 0px;
    }

    .content.pull-right {
        margin: auto;
    }
}

@media screen and (max-width: 722px) {
    .navbar-topnav.affiliate-topnav #mobile-header-nav.show .navbar-left {
        background-color: rgba(0, 0, 0, 0.9);
    }

    .navbar-topnav.affiliate-topnav {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .affiliate.container.white-bg.form {
        padding-left: 10px !important;
        padding-right: 10px !important;
        align-items: center;
    }

    .affiliate.container .content.pull-right {
        margin: auto;
        align-items: center;
    }

    .affiliate.container .content.pull-right h1,
    .affiliate.container .content.pull-right h3 {
        width: 90%;
    }

    .affiliate.container .title-large {
        font-size: 4.4rem !important;
    }

    .top-section.affiliate.container {
        background-image: url('../../assets/Affiliate/first-section-mobile.jpg');
    }

    .bottom-section.affiliate.container .details .content-bg {
        background-position-x: center;
    }

    .affiliate.container .content .btn,
    .affiliate.container .contact-form-submit {
        margin-top: 25%;
        min-height: 80px;
    }

    .affiliate.container .btn.full-width {
        min-width: fit-content;
        padding: 10px;
        margin: auto auto 40px !important;
    }

    .affiliate.container .contact-form-submit {
        margin: auto;
    }
}

@media screen and (min-width: 992px) {
    .navbar-topnav.affiliate-topnav .navbar-toggle.navbar-toggle-custom {
        padding: 10px 10px;
    }
}

.affiliate-sign-up-modal {
    position: fixed;
    inset: 0px;
    background: rgba(0, 0, 0, 0.8) none repeat scroll 0% 0%;
    z-index: 99999;
    transition: opacity 1s ease-in 0s;
    pointer-events: auto;
    overflow-y: auto;
}

.affiliate-modal-container {
    height: 530px;
    width: auto;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.affiliate-modal-content {
    height: 100%;
    justify-content: space-between;
}

.affiliate-modal-content .affiliate-sign-up-form {
    min-width: 315px;
    min-height: 100%;
    justify-content: space-between;
    align-items: center;
    flex: 0.7;
}

.input-wrapper {
    flex: 0.9;
    justify-content: space-evenly;
    padding: 2%;
    align-items: center;
}

.affiliate-modal-content .anchor-right-img {
    flex: 0.3;
}

.affiliate-sign-up-form .member-login-section .white {
    color: #595959;
    margin: auto 10px;
    flex: 1;
}

.affiliate-sign-up-form small,
.affiliate-sign-up-form .member-login-section {
    color: rgb(87, 87, 87);
}

.affiliate-sign-up-form small,
.affiliate-sign-up-form small a,
.affiliate-sign-up-form .error-text {
    font-family: "Roboto", Helvetica, Arial, sans-serif !important;
}

.affiliate-sign-up-form .member-login-section {
    background-color: #f5f5f5;
    width: 100%;
    flex: 0.1;
}

.affiliate-sign-up-form input {
    border: 2px solid rgb(177, 177, 177);
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    min-width: 90%;
    color: rgb(117, 117, 117);
}

.affiliate-sign-up-form input::placeholder {
    color: gray;
}

.affiliate-sign-up-form input:focus {
    outline: none;
}

.affiliate-sign-up-form .btn.btn-red {
    font-size: 2rem;
    font-weight: 900;
    line-height: 48px;
    padding: 0px !important;
    min-height: 48px;
}

.affiliate-sign-up-form .btn.btn-red:focus {
    outline: none;
    color: #fff;
}

.sign-up-logo {
    min-height: 30px;
}

.affiliate-sign-up-form .list-unstyled.modal-bullet-points.darker,
.affiliate-sign-up-form .modal-bullet-points-title {
    color: gray;
}

.affiliate-sign-up-form .list-unstyled.modal-bullet-points.darker {
    margin: 0px auto;
    width: 250px !important;
}

.affiliate-sign-up-form .error-text {
    min-height: 20px;
    font-size: 1em;
    margin: 0px;
}

@media screen and (max-width: 915px) {
    .affiliate-modal-content .anchor-right-img {
        display: none;
    }

    .affiliate-modal-content .affiliate-sign-up-form {
        flex: 1;
    }

    .affiliate-modal-content .affiliate-sign-up-form {
        min-width: 585px;
        width: auto;
    }
}

@media screen and (max-width: 650px) {
    .affiliate-modal-content .affiliate-sign-up-form {
        min-width: 90vw;
    }
}

.affiliate.container.bottom-section .content {
    flex-direction: row;
}

.audition-form-success-message button {
    background-color: gray;
    border: none;
    font-size: 32px;
    color: #fff;
    font-family: 900;
    border-radius: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.audition-form-success-message h4 {
    text-align: center;
    font-family: inherit;
    font-weight: 900;
}

.desktop-img {
    display: none;
}

@media screen and (min-width: 772px) {
    .desktop-img {
        display: block;
    }

    .mobile-img {
        display: none;
    }
}

@media (orientation: portrait) {
    .desktop-img {
        display: none;
    }

    .mobile-img {
        display: block;
    }
}

.affiliate-brand {
    width: 33%;
}

.affiliate-brand img {
    width: 100%;
}

.affiliate-detail img.brand {
    height: 50px;
    width: auto;
}
