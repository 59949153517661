.mobile-pub-cta {
  width: 168px;
  display: inline-block;
}

.mobile-pub-cta .btn {
  display: block;
  padding: 15px 0;
  text-align: center;
  background-color: #252525;
  color: #FFF;
  font-family: "Roboto", Arial,, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 0;
  margin-left: 20px;
}

.mobile-pub-cta .btn:hover,
.mobile-pub-cta .btn:active {
  background-color: #0c0c0c;
  color: #FFF
}

.mobile-pub-close {
  position: absolute;
  top: 5px;
  right: 15px;
  padding: 5px;
}

.mobile-pub-close:hover {
  cursor: pointer;
}

#premium-upgrade-bar {
  background-color: #006fb4
}

@media (min-width: 768px) {
  #premium-upgrade-bar {
      padding-top: 20px;
      min-height: 94px;
      max-height: 94px;
      overflow: hidden
  }
}

#premium-upgrade-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000
}

#premium-upgrade-bar .pub-mobile {
  display: block;
  margin: 0;
  text-align: center;
  text-decoration: none;
  line-height: 29px;
  color: #FFF;
  font-family: "Oswald", sans-serif;
  font-size: 19px
}

@media (max-width: 768px) {
  #premium-upgrade-bar .pub-mobile {
      padding: 20px 30px;
  }
}
#premium-upgrade-bar .pub-mobile span {
  color: #252525;
  text-transform: uppercase
}

#premium-upgrade-bar .pub-desktop {
  display: none;
  position: relative;
  color: #FFF
}

@media (min-width: 991px) {
  #premium-upgrade-bar .pub-desktop {
      display: block
  }
}

#premium-upgrade-bar .pub-desktop .pub-info {
  display: block
}

#premium-upgrade-bar .pub-desktop .pub-info p.h2 {
  margin: 0;
  font-family: "Oswald", sans-serif;
  font-size: 26px;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%
}

@media (min-width: 992px) {
  #premium-upgrade-bar .pub-desktop .pub-info p.h2 {
      line-height: 50px;
      font-size: 20px
  }
}

@media (min-width: 1200px) {
  #premium-upgrade-bar .pub-desktop .pub-info p.h2 {
      line-height: 34px;
      font-size: 24px
  }
}

#premium-upgrade-bar .pub-desktop .pub-cta .pub-close:hover {
  cursor: pointer;
}


#premium-upgrade-bar .pub-desktop .pub-info p.h2>span {
  display: none
}

@media (min-width: 992px) {
  #premium-upgrade-bar .pub-desktop .pub-info p.h2>span {
      display: inline
  }
}

#premium-upgrade-bar .pub-desktop .pub-info p.h2>span a {
  font-family: "Oswald", sans-serif;
  color: #252525;
  text-transform: uppercase
}

#premium-upgrade-bar .pub-desktop .pub-info small {
  display: inline-block;
  text-transform: uppercase;
  font-weight: normal;
  color: #252525;
  font-family: "Roboto", Arial,, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%
}

#premium-upgrade-bar .pub-desktop .pub-pricing,
#premium-upgrade-bar .pub-desktop .pub-cta {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0
}

#premium-upgrade-bar .pub-desktop .pub-pricing {
  right: 168px;
  margin: 0 35px
}

#premium-upgrade-bar .pub-desktop .pub-pricing table {
  color: #FFF;
  font-family: "Oswald", sans-serif;
  border: none;
  border-collapse: collapse
}

#premium-upgrade-bar .pub-desktop .pub-pricing table .price {
  font-size: 52px;
  line-height: 52px;
  vertical-align: top
}

#premium-upgrade-bar .pub-desktop .pub-pricing table .price .dollars {
  padding-right: 5px;
  letter-spacing: 1px
}

#premium-upgrade-bar .pub-desktop .pub-pricing table .price .cents {
  font-size: 0.7em;
  line-height: 1em;
  padding-top: 2px
}

#premium-upgrade-bar .pub-desktop .pub-pricing table .period {
  font-size: 10px;
  line-height: 10px;
  vertical-align: bottom
}

#premium-upgrade-bar .pub-desktop .pub-pricing table .period .units {
  letter-spacing: 1.4px;
  text-transform: uppercase
}

#premium-upgrade-bar .pub-desktop .pub-pricing table .notation {
  font-size: 12px;
  font-family: "Roboto", Arial,, sans-serif;
  color: #252525;
  text-align: right;
  line-height: 24px
}

#premium-upgrade-bar .pub-desktop .pub-cta {
  width: 168px;
  right: 0;
  text-align: right
}

#premium-upgrade-bar .pub-desktop .pub-cta .btn {
  display: block;
  padding: 15px 0;
  text-align: center;
  background-color: #252525;
  color: #FFF;
  font-family: "Roboto", Arial,, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 0;
  margin-bottom: 5px
}

#premium-upgrade-bar .pub-desktop .pub-cta .btn:active,
#premium-upgrade-bar .pub-desktop .pub-cta .btn:hover {
  background-color: #0c0c0c;
  color: #FFF
}

#premium-upgrade-bar .pub-desktop .pub-cta .pub-close {
  font-family: "Roboto", Arial, sans-serif;
  color: #FFF;
  text-transform: uppercase;
  font-size: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
