.countDownTimer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
  background-color: #222323;
  padding: .5rem;
  cursor: pointer;
  border-radius: 1px;
}

.countDownTimer-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.countDownTimer-item-label {
  font-size: 12px;
}

.countDownTimer-item-value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.countDownTimer-item-dot-wrap {
  margin-top: 1rem;
}

.countDownTimer-item-dot {
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: white;
  margin-top: .5rem;
}


@media (max-width: 991px) {
  .countDownTimer {
    height: 30px;
    margin: 0;
    margin-top: 10px;
  }

  .countDownTimer-item-label {
    font-size: 6px;
  }

  .countDownTimer-item-dot-wrap {
    margin-top: .5rem;
  }

  .countDownTimer-item-dot {
    margin-top: .5rem;
    width: 3px;
    height: 3px;
  }
}