.guest-home-nav .navbar-brand,
.guest-nav-menu.guest-home-nav.navbar-topnav.guest-home-nav {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  position: relative;
}

.guest-home-nav * {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
}

.guest-home-nav .navbar-brand {
  margin-left: 20px;
  z-index: 100;
}

.navbar-topnav.guest-home-nav {
  background-color: #0d0a22;
}

.navbar-topnav.guest-home-nav {
  position: relative;
  border: none;
  z-index: 1001;
  width: 100%;
  font-family: 'Roboto', Helvetica, sans-serif;
}

.navbar-topnav.guest-home-nav #mobile-header-nav {
  background-color: rgba(0, 0, 0, 0);
}

.navbar-topnav.guest-home-nav .navbar-brand {
  position: absolute;
  left: 0;
  right: 0;
}

.navbar-topnav.guest-home-nav #mobile-header-nav .navbar-left li,
.navbar-topnav.guest-home-nav #mobile-header-nav .navbar-left li a {
  display: flex;
  align-items: center;
}

.navbar-topnav.guest-home-nav #mobile-header-nav .navbar-left {
  display: flex;
  position: absolute;
  z-index: 98;
  background-color: #0d0a22;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 60%;
  height: 100%;
}

@keyframes toggleMenuOpen {
  from {
    top: -550px;
  }

  to {
    top: 0px;
  }
}

.navbar-topnav.guest-home-nav #mobile-header-nav.show .navbar-left {
  animation: toggleMenuOpen 0.5s;
  animation-iteration-count: 1;
  right: 0px;
  top: -550px;
}

.navbar-topnav.guest-home-nav #mobile-header-nav .navbar-left,
#guest-page .anchor-category-title,
.navbar-topnav.guest-home-nav .navbar-topnav.subnav,
.navbar-topnav.guest-home-nav .navbar-toggle.navbar-toggle-custom,
#navigation
  .guest-home-nav
  .navbar-collapse
  .navbar-right
  .pull-right
  .purchase.cta-join {
  display: none;
}

.navbar-topnav.guest-home-nav .navbar-collapse .navbar-left {
  display: none;
}

.navbar-topnav.guest-home-nav #mobile-header-nav {
  display: block !important;
}

.navbar-topnav.guest-home-nav .navbar-collapse .navbar-right {
  display: block !important;
}

.navbar-topnav.guest-home-nav .navbar-login-link {
  position: absolute;
  z-index: 999;
  right: 80px;
  height: 100%;
}

.guest-home-nav .navbar-brand,
.navbar-topnav.guest-home-nav .navbar-login-link,
.navbar-topnav.guest-home-nav .navbar-right .navbar-login-link a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-topnav.guest-home-nav .navbar-left li a {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.navbar-topnav.guest-home-nav .navbar-right .navbar-login-link a {
  font-size: 1.8rem !important;
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  color: #fff !important;
  background-image: linear-gradient(#da3b3a, #550b11);
  height: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  border-radius: 20px;
  letter-spacing: 1px;
}

.navbar-topnav.guest-home-nav .navbar-right .navbar-login-link a:hover {
  color: #e2e1e1 !important;
}

#red-background {
    background-image: url('../../../assets/GuestHome/red-shape-large.png');
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
    background-size: contain;
    position: absolute;
    top: -180px;
    left: -55px;
    z-index: 99;
    transform: rotate(180deg);
  }

.guest-home-nav #red-background {
  top: -185px;
  left: -185px;
  height: 260px;
  width: 440px;
  transform: rotate(175deg);
}

.guest-home-nav .nav-icon-absolute {
  top: -5px;
}

@media screen and (max-width: 1600px) {
  .guest-home-nav #red-background {
    top: -185px;
    left: -180px;
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 1201px) {
  .navbar-topnav.guest-home-nav #mobile-header-nav .navbar-left {
    display: flex !important;
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .guest-home-nav #mobile-header-nav .navbar-right {
    margin-right: 50px;
  }

  .navbar-topnav.guest-home-nav #mobile-header-nav .navbar-left {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .guest-home-nav #navbar-header .navbar-toggle {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }

  .navbar-topnav.guest-home-nav #mobile-header-nav .navbar-left {
    padding-top: 50px;
  }

  .navbar-topnav.guest-home-nav .navbar-toggle.navbar-toggle-custom {
    background-color: transparent;
    border: none;
    border-radius: 100%;
    display: block;
    position: absolute;
    z-index: 9999;
    right: 20px;
    height: 45px;
    color: #fff;
  }

  .navbar-topnav.guest-home-nav #mobile-header-nav.show .navbar-left {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#1b133c, #2f2662);
    flex-direction: column;
    top: 0;
    left: auto;
    right: 0;
    transform: translate(0, 0);
    min-width: 240px;

    height: auto;
  }
}

@media screen and (max-width: 992px) {
  .guest-home-nav .navbar-lg.navbar {
    min-height: 65px;
  }

  .guest-home-nav #red-background {
    left: -215px;
    top: -210px;
    transform: rotate(185deg);
  }

  .guest-home-nav #navbar-header .navbar-toggle {
    margin-top: 6px;
  }
}

@media screen and (max-width: 722px) {
  .navbar-topnav.guest-home-nav #mobile-header-nav.show .navbar-left {
    top: 50px;
    padding-top: 0px;
  }
}

@media screen and (max-width: 500px) {
  #guest-page #current-anchors .anchors-slider .thumbnail a img {
    min-height: 350px;
    width: auto;
  }

  .guest-home-nav .navbar-brand {
    margin-left: 0px;
    width: 135px;
  }

  .navbar-topnav.guest-home-nav .navbar-right .navbar-login-link a {
    padding-left: 10px;
    padding-right: 10px;
  }
}
