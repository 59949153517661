.contentContainer * {
  margin: 0;
  padding: 0;
}

.modalContainer {
  top: 50% !important;
  position: absolute;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  margin: 0 !important;
  width: auto !important;
  padding: 0 !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  min-height: 452px;
}

.closeButton {
  position: fixed;
  top: 10px;
  right: 10px;
}

.closeButton svg {
  font-size: 2.5rem;
}

.closeButton,
.nextButton,
.backButton,
.otpConfirmButton,
.wrongNumber,
.retryContainer button,
.acceptButton,
.cancelButton {
  background: none;
  border: none;
}

.contentContainer {
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  max-width: 75%;
  padding: 0rem;
  position: relative;
  min-height: inherit;
}

.nextButton,
.backButton,
.contentContainer .input,
.acceptButton,
.cancelButton {
  width: 100%;
  min-height: 4.25rem;
  margin: 0 auto;
  padding: 0px 5px;
  border-radius: 5px;
}

.backButton,
.link,
.link:hover {
  text-decoration: underline;
  color: #ac020a;
}

.backButton {
  width: 120px;
}

.resendEmail {
  color: #fff;
  background-image: linear-gradient(#da3b3a, #550b11);
  min-height: 4.25rem;
  margin: 0 auto;
  padding: 0px 5px;
  border-radius: 5px;
  border: none;
  width: 100%;
}

.contentContainer input {
  border: 2px solid rgb(202, 202, 202);
}

.contentContainer h6 {
  font-size: 2rem;
  margin-bottom: 1.5rem auto 0px;
  text-align: center;
}

.contentContainer p {
  text-align: center;
  font-size: 1.25rem;
}

.nextButton,
.acceptButton,
.cancelButton {
  font-size: 1.8rem;
  color: #fff;
  background-image: linear-gradient(#da3b3a, #550b11);
  height: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nextButton:disabled,
.otpConfirmButton:disabled,
.cancelButton,
.resendEmail:disabled {
  background-image: none;
  background-color: gray;
  color: #fff;
}

.retryContainer button:disabled {
  color: #000;
}

.input {
  display: flex;
}

.phoneInput ul li.searchClass {
  padding: 10px !important;
  margin: 0px;
}

.phoneInput .searchClass input[type="search"] {
  margin-left: 0px;
  padding: 5px;
  padding-right: 20px;
}

.phoneInput li.searchClass span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 10px;
}

.input input,
.phoneInput input[type="tel"],
.phoneInput input[type="search"] {
  height: 4.25rem !important;
  width: 100% !important;
}

.country-list {
  z-index: 9999 !important;
}

/*OTP INPUT STYLES*/

.smsInputContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px auto 0px;
}

.otpInputContainer,
.retryContainer {
  display: flex;
}

.otpInputContainer {
  margin: 10px auto;
}

.retryContainer * {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.25rem;
}

.retryContainer {
  margin: 0px auto 15px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.retryContainer button,
.wrongNumber {
  color: #da3b3a;
}

.retryContainer button {
  margin: auto 5px;
}

.otpInputContainer input {
  font-size: 2rem;
  height: 40px;
  width: 40px;
  margin: 10px 15px 0px 0px;
  border-radius: 5px;
  text-align: center;
}

.otpConfirmButton,
.wrongNumberContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otpConfirmButton {
  padding: 1rem;
  border-radius: 5px;
  background-image: linear-gradient(#da3b3a, #550b11);
  color: #fff;
  margin-bottom: 1rem;
  width: 100%;
}

.wrongNumber {
  margin: auto;
  width: auto;
}

.wrongNumberContainer {
  width: 100%;
  margin-top: 5px;
}

hr {
  width: 100%;
  margin-bottom: 10px;
}

/*CONFIRMATION CONTAINER*/

.confirmationContainer,
.dataContainer,
.textContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.confirmationContainer {
  flex-direction: column;
  padding: 0px 50px;
  color: #000;
  min-height: inherit;
  justify-content: flex-start;
}

.dataContainer {
  border: 1px solid rgb(71, 190, 255);
  border-radius: 5px;
  padding: 1.5rem 2rem;
  margin-top: 3rem;
}

.dataContainer svg {
  fill: rgb(71, 190, 255);
  font-size: 1.8rem;
  margin-right: 5px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40px;
}

.buttonContainer button {
  margin: 20px 5px;
  border-radius: 20px;
}

.textContainer {
  margin: 0px auto 0px auto;
  flex-direction: column;
  justify-content: space-between;
}

.textContainer h2 {
  margin: 2rem auto 1rem;
}

.textContainer hr {
  margin: 1rem auto 5rem;
}

.green {
  color: green;
  margin: 0px auto 10px auto;
}

.red {
  color: red;
  margin: 0px auto 10px auto;
}

.countdown {
  margin: 5px auto;
}

.inputContainer {
  width: 100%;
  position: relative;
}

.loaderContainer {
  position: absolute;
  right: 0px;
  top: 50%;
  translate: -50% -50%;
}

@media screen and (max-height: 455px) {
  .modalContainer {
    min-height: auto;
    height: 452px;
    max-height: 95vh;
    overflow: scroll;
  }

  .contentContainer {
    justify-content: space-between;
    height: 90%;
  }

  .buttonContainer {
    position: relative;
    bottom: 0px;
  }
}

@media screen and (max-width: 500px) {
  .contentContainer {
    width: 100%;
    max-width: 90%;
  }
}
