.flipdown.flipdown__theme-light {
  font-family: sans-serif;
  font-weight: bold;
}

.flipdown.flipdown__theme-light .rotor-group-heading:before {
  color: #EEEEEE;
}

.flipdown.flipdown__theme-light .rotor,
.flipdown.flipdown__theme-light .rotor-top,
.flipdown.flipdown__theme-light .rotor-leaf-front {
  color: #222222;
  background-color: #DDDDDD;
}

.flipdown.flipdown__theme-light .rotor-bottom,
.flipdown.flipdown__theme-light .rotor-leaf-rear {
  color: #333333;
  background-color: #EEEEEE;
}

.flipdown.flipdown__theme-light .rotor:after {
  border-top: solid 1px #222222;
}


.flipdown .rotor {
  position: relative;
  float: left;
  width: 20px;
  height: 26px;
  margin: 0px 2px 0px 2px;
  border-radius: 2px;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  perspective: 300px;
}

.flipdown .rotor-top,
.flipdown .rotor-bottom {
  overflow: hidden;
  position: absolute;
  width: 20px;
  height: 13px;
}

.flipdown .rotor-leaf {
  z-index: 1;
  position: absolute;
  width: 20px;
  height: 26px;
  transform-style: preserve-3d;
  transition: transform 0s;
}

.flipdown .rotor-leaf.flipped {
  transform: rotateX(-180deg);
  transition: all 0.5s ease-in-out;
}

.flipdown .rotor-leaf-front,
.flipdown .rotor-leaf-rear {
  overflow: hidden;
  position: absolute;
  width: 20px;
  height: 13px;
  margin: 0;
  transform: rotateX(0deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.flipdown .rotor-leaf-front {
  line-height: 26px;
  border-radius: 2px 2px 0px 0px;
}

.flipdown .rotor-leaf-rear {
  line-height: 0px;
  border-radius: 0px 0px 4px 4px;
  transform: rotateX(-180deg);
}

.flipdown .rotor-top {
  line-height: 26px;
  border-radius: 2px 2px 0px 0px;
}

.flipdown .rotor-bottom {
  bottom: 0;
  line-height: 0px;
  border-radius: 0px 0px 2px 2px;
}

.flipdown .rotor:after {
  content: '';
  z-index: 2;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 20px;
  height: 13px;
  border-radius: 0px 0px 2px 2px;
}


@media (max-width: 991px) {
  .flipdown .rotor {
    width: 12px;
    height: 15px;
    font-size: 1.5rem;
    font-weight: 550;
  }
  
  .flipdown .rotor-top,
  .flipdown .rotor-bottom {
    width: 12px;
    height: 8px;
  }
  
  .flipdown .rotor-leaf {
    width: 12px;
    height: 15px;
  }
  
  .flipdown .rotor-leaf-front,
  .flipdown .rotor-leaf-rear {
    width: 12px;
    height: 8px;
  }
  
  .flipdown .rotor-leaf-front {
    line-height: 15px;
  }
  
  .flipdown .rotor-top {
    line-height: 15px;
  }
  
  .flipdown .rotor:after {
    width: 12px;
    height: 8px;
  }
}